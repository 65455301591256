/* -----------------------------------------
  =Default css to make the demo more pretty
-------------------------------------------- */

.content {
  padding: 15px;
  overflow: hidden;
  background-color: #e7e7e7;
  background-color: rgba(0, 0, 0, 0.06);
}

.clear {
  clear: both;
}

/* -----------------------------------------
    =CSS3 Loading animations
  -------------------------------------------- */

/* =Elements style
  ---------------------- */
.load-wrap {
  margin: auto;
  position: relative;
  top: 50%;
  width: fit-content;
}

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #00b1f5;
}
/* =Animate the stuff
  ------------------------ */

.load-2 .line:nth-last-child(1) {
  animation: loading 1.5s 1s infinite;
}
.load-2 .line:nth-last-child(2) {
  animation: loading 1.5s 0.5s infinite;
}
.load-2 .line:nth-last-child(3) {
  animation: loading 1.5s 0s infinite;
}

@keyframes loading {
  0% {
    width: 15px;
  }
  50% {
    width: 35px;
  }
  100% {
    width: 15px;
  }
}

.loader-cotnainer {
  position: absolute;
  top: 0px;
  z-index: 3051;
  background-color: rgb(255 255 255 / 65%);
  height: 100vh;
  width: 100vw;
}
