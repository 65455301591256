.input-container {
  .error-statement {
    color: #f06548;
    font-weight: 500;
    font-size: 12px;
  }

  .form-control {
    font-size: 0.9rem;
    border-radius: 0rem;
    border: 1px solid var(--input-border);
  }

  .form-control:focus,
  .ant-picker-focused {
    border: 1px solid #2684ff !important;
    box-shadow: 0 0 0 1px #2684ff !important;
  }

  .form-label {
    margin: 0rem;
    font-size: 14px;
    font-weight: 600;
  }

  .error-border {
    border: 1px solid #f06548 !important;
    box-shadow: 0 0 0 1px #f06548 !important;
    border-radius: 0px !important;
  }

  .select-input.error-border > div {
    border: none;
    border-radius: 5px;
  }

  .required {
    margin-left: 4px;
    color: #f06548;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-picker {
    max-height: 36px;
    border-radius: 0px !important;
    padding: 6px 10px 10px 12px !important;
    width: 100%;
    border-radius: 0.25rem;
    padding: 8px 10px 10px 12px;
    border: 1px solid var(--input-border);
    box-shadow: none;
    input {
      font-size: 0.8125rem;
      color: var(--vz-body-color) !important;
    }
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-column {
    flex-direction: column;
  }

  .toggle.ant-switch {
    height: 38px;
    background-color: #ccc !important;
    color: #000 !important;
    border: 0px solid #2684ff !important;
    box-shadow: none !important;

    &.ant-switch-checked {
      background-color: #1677ff !important;
    }

    .ant-switch-inner {
      .ant-switch-inner-checked,
      .ant-switch-inner-unchecked {
        font-size: 0.9rem;
      }
      .ant-switch-inner-unchecked {
        color: #000;
        margin-top: -20px;
      }
    }
    .ant-switch-handle {
      top: 4px;
      inset-inline-start: 5px;
      width: 28px;
      height: 28px;
    }

    &.ant-switch-checked .ant-switch-handle {
      inset-inline-start: calc(100% - 30px);
    }

    .ant-switch-handle:before {
      border-radius: 50px;
    }
  }
}

.m-5-0 {
  margin: 5px 0px;
}

.pr-1 {
  padding-right: 8px;
}

.form-control.ant-picker-disabled,
.ant-picker input:disabled {
  background-color: var(--bs-secondary-bg) !important;
}

.ant-checkbox-disabled + span {
  color: #000;
}
.no-border {
  border: 1px solid var(--input-disabled) !important;
  padding: 5px;
}

.password-view {
  cursor: pointer;
  position: absolute;
  right: 10px;
  color: hsl(0, 0%, 60%);
}

.clipboard-view {
  cursor: pointer;
  position: absolute;
  right: 15px;
  color: hsl(0, 0%, 60%);
}

.right-35-px {
  right: 35px !important;
}

.subtext {
  float: right;
  font-size: 10px;
  color: hsl(0, 0%, 60%);
  font-weight: 500;
}

.custom-disable {
  .required,
  .ant-picker-suffix,
  .select-input > div > div:nth-child(2),
  .error-statement,
  .ant-switch-handle {
    display: none;
  }

  .ant-radio-wrapper-disabled,
  .select-input > div > div:nth-child(1) > div,
  input {
    color: #000 !important;
  }

  .select-input > div,
  .form-control.ant-picker-disabled,
  .ant-picker input:disabled,
  .form-control:disabled {
    background-color: #e9ecef !important;
    // border: none !important;
    box-shadow: none !important;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }

  .form-control::placeholder {
    color: transparent;
  }
}
.w-150-px {
  width: 150px;
}
.w-200-px {
  width: 200px;
}
