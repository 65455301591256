.custom-table-container {
  padding: 20px 10px;
  overflow: auto;

  .ant-table {
    font-size: 13px;
  }

  .ant-table-thead .ant-table-cell {
    color: #000000;
  }

  .empty-margin {
    margin: 50px 0px;
  }

  .px-7-px {
    padding-left: 7px;
    padding-right: 7px;
  }

  .py-7-px {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .py-5-px {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  button {
    margin: 0px;
  }
  .anticon {
    vertical-align: 0.125em;
  }
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #f4f6f9;
  }

  .ant-pagination-prev button,
  .ant-pagination-next button,
  .ant-pagination-item {
    cursor: pointer;
    border: 0px;
    background-color: rgba(145, 158, 171, 0.16);
    color: #212b36;
  }
  .ant-pagination-item-active {
    color: white;
    background-color: #626262;
    border: 1px solid rgba(145, 158, 171, 0.16);
  }
  .ant-table-pagination.ant-pagination {
    position: fixed;
    margin-top: 50px;
    right: 20px;
  }
  .ant-table-cell-fix-left,
  .ant-table-thead > tr > th {
    background-color: #d6dbdf;
  }

  .ant-table-content {
    height: 82vh;
    overflow: auto !important;
  }
  .ant-table-container {
    border-left: none !important;
  }
  .ant-table-content > table {
    border-left: 1px solid #f0f0f0;
  }
}

.custom-inline-table-container {
  margin-bottom: 5px;
  overflow: auto;

  .ant-table {
    font-size: var(--vz-body-font-size) !important;
  }

  .ant-table-thead .ant-table-cell {
    color: #565656;
  }
  .empty-margin {
    margin: 50px 0px;
  }

  .ant-table-thead th {
    padding: 10px !important;
  }

  .p-10 {
    padding: 10px;
  }
  .p-0 {
    padding: 0px;
  }
  .p-5 {
    padding: 5px !important;
  }
  button {
    margin: 0px;
  }
  .anticon {
    vertical-align: 0.125em;
  }
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #f4f6f9;
  }

  .ant-table-placeholder:not(.ant-empty) {
    display: none;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.balance-sheet-container {
  .custom-table-container {
    padding: 20px 10px;
    overflow: auto;

    .ant-table {
      font-size: var(--vz-body-font-size) !important;
    }

    .ant-table-thead .ant-table-cell {
      color: #000000;
    }
    .empty-margin {
      margin: 50px 0px;
    }

    .p-10 {
      padding: 0px;
    }
    button {
      margin: 0px;
    }
    .anticon {
      vertical-align: 0.125em;
    }
    .ant-table-cell-row-hover {
      cursor: pointer;
      background-color: #f4f6f9;
    }

    .ant-pagination-prev button,
    .ant-pagination-next button,
    .ant-pagination-item {
      cursor: pointer;
      border: 0px;
      background-color: rgb(64, 81, 137);
      color: rgb(255, 255, 255);
    }
    .ant-pagination-item-active {
      color: rgb(64, 81, 137);
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(64, 81, 137);
    }
  }
}

.ant-table-thead tr {
  position: sticky;
  top: 0px;
  z-index: 3;
}
