.pan-wrapper {
  .pan-delivered {
    background-color: #02ac21;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
  }
  .acknowledgement-received {
    background-color: #a4e99f;
    color: #000;
    padding: 5px 8px;
    border-radius: 5px;
  }

  .application-to-be-sent {
    background-color: #f8e7b5;
    color: #000;
    padding: 5px 8px;
    border-radius: 5px;
  }
  .application-sent {
    background-color: #ffc107;
    color: #000;
    padding: 5px 8px;
    border-radius: 5px;
  }
}
