.data-wrapper {
  padding: 5px;
  border-radius: 5px;
}

.data-uncategorized {
  color: var(--white);
  background-color: var(--uncategorized);
}

.data-same {
  background-color: var(--same);
}

.data-as-per-unit {
  background-color: var(--todo);
}

.data-nill {
  background-color: var(--nil);
}

.data-todo {
  background-color: var(--todo);
}

.data-in-progress {
  background-color: var(--inprogress);
}

.data-done {
  background-color: var(--done);
}

.data-no {
  color: var(--white);
  background-color: var(--no);
}

.add-new-icon {
  position: absolute;
  bottom: 75px;
  right: 50px;
  background-color: deepskyblue;
  color: white;
  padding: 8px;
  border-radius: 50px;
  font-size: x-large;
  cursor: pointer;
}

.ant-card .ant-card-body {
  padding: 12px;
}

.services-item-border {
  background-color: #219ebc;
  color: white;
  font-weight: 500;
  padding: 5px 10px;
}

.custom-client {
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  margin-bottom: 2px;
}

.client-service {
  font-size: 12px;
  padding: 0;
}

.client-id {
  font-size: 14px;
}

.client-name {
  font-size: 16px;
  font-weight: 700;
}

.client-contact {
  font-size: 14px;
}
.client-gst {
  font-weight: 700;
}
.header {
  font-weight: 600;
  font-size: 16px;
}

.contact-icon {
  margin-right: 8px;
  min-width: 38px;
  background-color: #b3b3b3;
}

.address-icon {
  font-size: 20px;
  margin-right: 8px;
}

.client-card {
  background-color: #e6f4ff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
