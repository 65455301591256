.due-date-wrapper {
  .new-popup-icon {
    display: none;
  }
}

.min-width-300-px {
  min-width: 300px;
}

.min-width-70-px {
  min-width: 70px;
}

.min-width-150-px {
  min-width: 150px;
}
