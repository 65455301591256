.mt-50 {
  margin-top: 50px !important;
}

.status-active {
  background-color: #2a9d8f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}

.status-inactive {
  background-color: #e76f51;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}

.ant-upload-list-item-container,
.ant-upload.ant-upload-select {
  height: 200px !important;
  width: 200px !important;
}

.ant-upload-list-item-actions > a {
  display: none;
}

.change-password-button {
  background-color: transparent;
  border: none;
  width: 50px;
}

.change-password-button:hover,
.change-password-button:focus,
.change-password-button:active {
  background-color: #ccc !important;
}
