.dsc-wrapper {
  .status-completed {
    background-color: #2a9d8f;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
  }

  .status-pending {
    background-color: #ffc107;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
  }
  .status-rejected {
    background-color: #e76f51;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
  }
}
