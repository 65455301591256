.search-table {
  padding: 0px 10px;
  overflow: auto;

  .form-container {
    border-bottom: 1px solid var(--vz-border-color) !important;
    align-items: center;
    display: flex;
    padding: 10px;
  }
  .date-filter {
    .form-control {
      border: 1px solid var(--vz-input-border);
    }

    .select-input.error-border > div {
      border: none;
    }

    .ant-picker {
      width: 100%;
      border-radius: 0.25rem;
      padding: 8px 10px 10px 12px;
      border: 1px solid var(--vz-input-border);
      box-shadow: none;
      input {
        font-size: 0.9rem;
        color: var(--vz-body-color);
        text-align: center;
      }
    }
  }

  .ant-table {
    font-size: 13px;
  }

  .ant-table-thead .ant-table-cell {
    color: #000000;
  }
  .empty-margin {
    margin: 50px 0px;
  }

  .px-10-px {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-10-px {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .px-7-px {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .py-7-px {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .py-5-px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .form-container button {
    margin: 0px;
    border-radius: 5px !important;
    padding-inline-start: 10px !important;
    padding-inline-end: 10px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px !important;
  }

  .anticon {
    vertical-align: 0.125em;
  }

  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #f4f6f9;
  }

  .ant-pagination-prev button,
  .ant-pagination-next button,
  .ant-pagination-item {
    cursor: pointer;
    border: 0px;
    background-color: rgba(145, 158, 171, 0.16);
    color: #212b36;
  }
  .ant-pagination-item-active {
    color: white;
    background-color: #626262;
    border: 1px solid rgba(145, 158, 171, 0.16);
  }
  .ant-table-pagination.ant-pagination {
    position: fixed;
    margin-top: 50px;
    right: 20px;
  }
  .ant-table-cell-fix-left,
  .ant-table-thead > tr > th {
    background-color: #d6dbdf;
  }

  .ant-table-content {
    height: 73vh;
    overflow: auto !important;
  }
}

.custom-inline-table-container {
  margin-bottom: 5px;
  overflow: auto;

  .ant-table {
    font-size: var(--vz-body-font-size) !important;
  }

  .ant-table-thead .ant-table-cell {
    color: #565656;
  }
  .empty-margin {
    margin: 50px 0px;
  }

  .ant-table-thead th {
    padding: 10px !important;
  }

  .p-10 {
    padding: 10px;
  }
  .p-0 {
    padding: 0px;
  }
  .p-5 {
    padding: 5px !important;
  }
  button {
    margin: 0px;
  }
  .anticon {
    vertical-align: 0.125em;
  }
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #f4f6f9;
  }

  .ant-table-placeholder:not(.ant-empty) {
    display: none;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.ant-table-cell {
  border-radius: 0px !important;
}
