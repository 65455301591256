@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-toastify/dist/ReactToastify.css';

@import 'primereact/resources/primereact.css';
@import 'primereact/resources/themes/lara-light-blue/theme.css';

:root {
  --white: #fff;
  --uncategorized: #9a9a9a;
  --same: #dee7f9;
  --todo: #fee2d5;
  --nil: #d0f0fd;
  --done: #d1f7c4;
  --inprogress: #ffdce5;
  --no: #2d7ff9;
  --input-border: #d9d9d9;
  --input-disabled: #ccc;
}

.ant-table-cell {
  background-color: #f9fafb;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ccc;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
}

.form-control::placeholder {
  /* Most modern browsers support this now. */
  color: #ccc;
}

.w-20 {
  width: 20% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.color-grey {
  color: grey;
}

.ant-picker-dropdown {
  z-index: 3050 !important;
}

.w-170-px {
  width: 170px;
}

.text-1rem {
  font-size: 1rem !important;
}

.height-3rem {
  height: 3rem !important;
}

.ant-drawer {
  z-index: 1200 !important;
}

.ant-collapse-header-text {
  font-weight: 600;
  font-size: 16px;
}

.ant-space {
  width: 100% !important;
}

.ant-space-item {
  margin-bottom: 10px !important;
}

.ant-steps-item-description {
  padding-left: 5px;
}

.float-right {
  float: right;
}

.f-14-px {
  font-size: 14px;
}

.client-info-button {
  background-color: transparent;
  border: none;
  height: auto;
  padding-top: 0px;
  padding-bottom: 0px;
}

.client-info {
  margin-bottom: 0px;
}

.month-navigator {
  color: #000;
  margin-right: 1rem;
  cursor: pointer;
}

.log-wrapper {
  padding: 10px;
  .log-container {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: rgba(145, 158, 171, 0.12);
    .log-table {
      border-collapse: collapse;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;
      tr,
      th,
      td {
        border: 1px solid #ccc;
      }
      th,
      td {
        padding: 5px;
      }
    }
  }
}

.ellipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-250-px {
  width: 250px;
}

.p-datepicker {
  z-index: 1201 !important;
}

.w-full {
  width: 100%;
}

.tax-card-container {
  gap: 12px;
  .tax-card {
    width: 140px;
    text-align: center;
  }
}
