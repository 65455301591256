.ant-steps-item-description {
  color: #000000e0 !important;
}

.clipboard-section {
  font-size: 0.9rem;
  border-radius: 0rem;
  border: 1px solid #d9d9d9;
  padding: 15px;
  width: 100%;
}

.clipboard-icon-watsapp {
  font-size: 25px;
  bottom: 40px;
  color: #7f7f7f;
  cursor: pointer;
  position: absolute;
  right: 75px;
}

.clipboard-icon-copy {
  font-size: 25px;
  bottom: 40px;
  color: #7f7f7f;
  cursor: pointer;
  position: absolute;
  right: 35px;
}
.ant-dropdown {
  z-index: 2000;
}

.feedback-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  margin: 10px 0px 15px 0px;
  background: #e6f4ff;
}

.reminder-content {
  font-size: 12px;
  font-weight: 700;
  margin: 0px;
}

.due-date-card {
  background-color: #219ebc;
  color: #fff;
  padding: 2px 10px;
}
